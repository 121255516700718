export default class IgnoredNetworkError extends Error {
  // eslint-disable-next-line
  originalError: any;

  // eslint-disable-next-line
  constructor(originalError?: any) {
    super(originalError?.message);
    this.originalError = originalError;
  }
}
