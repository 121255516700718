import { getBrowserId } from '@amedia/browserid';

import log from '../utils/log';
import { UserAttributes } from '../types';
import store from '../store';
import { getInfo } from '../clients/nebula';
import { whenLoginStateResolved } from '../utils/loginState';
import { getCurrentSiteDomain } from '../utils/url';
import IgnoredNetworkError from '../exceptions/IgnoredNetworkError';
import allSettledPolyfill from '../polyfills/allSettledPolyfill';
import { attemptAutoLoginJob } from '../autologin';

import { getUser } from './authentication';

const getExtraData = () =>
  Promise.all([getBrowserId(), whenLoginStateResolved()]).then(
    ([browserId, isLoggedIn]) =>
      getInfo(
        getCurrentSiteDomain(),
        browserId,
        isLoggedIn
          ? store.get('userAttributes')?.trackingKey || undefined
          : undefined
      )
  );

const dataServiceMap: {
  [key in keyof UserAttributes]?: () => Promise<Partial<UserAttributes>>;
} = {
  uuid: getUser,
  name: getUser,
  trackingKey: getUser,
  access: getUser,
  extraData: getExtraData,
  privacyPreferences: getUser,
};

export async function requestAttributes(
  attributes: Array<keyof UserAttributes>
) {
  store.merge('internalState', {
    requestedAttributes: new Set([
      ...store.get('internalState').requestedAttributes,
      ...attributes,
    ]),
  });
  const requiredServices: Array<() => Promise<Partial<UserAttributes>>> = [];
  attributes.forEach((attribute) => {
    const service = dataServiceMap[attribute];
    if (!service) {
      log.warn(`Unknown attribute ${attribute} was requested`);
      return;
    }
    if (!requiredServices.includes(service)) {
      requiredServices.push(service);
    }
  });

  const promises: Promise<void>[] = [];
  requiredServices.forEach((service) => {
    promises.push(
      service()
        .then(async (data) => {
          store.merge('userAttributes', data);
          if (data.uuid !== undefined) {
            if (!data.uuid) {
              await attemptAutoLoginJob();
            }

            // We have tried to fetch uuid, so we now know if the user is logged in or not
            store.merge('state', {
              isLoggedIn: !!data.uuid,
            });
          }
        })
        .catch((error) => {
          if (error instanceof IgnoredNetworkError) {
            return;
          }
          throw error;
        })
    );
  });
  await (Promise.allSettled || allSettledPolyfill).bind(Promise)(promises);
}
