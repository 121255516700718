import * as rt from 'runtypes';

import getOrUpdate from '../utils/getOrUpdate';
import { AccessFeatures, UserAttributes } from '../types';
import log from '../utils/log';
import { schemaVerifiedFetch } from '../utils/fetcher';
import IgnoredNetworkError from '../exceptions/IgnoredNetworkError';

const PublicationSchema = rt.Record({
  id: rt.Number,
  domain: rt.String,
});
export const CheckAccessForPaperSchema = rt.Record({
  status: rt.Literal('success'),
  access: rt.Union(rt.Literal('granted'), rt.Literal('denied')),
  publication: PublicationSchema,
  access_features: rt.Array(rt.String),
});

export type CheckAccessForPaper = rt.Static<typeof CheckAccessForPaperSchema>;

const GetVstokenSchema = rt.Record({
  // status: rt.Literal('success'), TODO check actual response
  status: rt.Union(rt.Literal('success'), rt.Literal('failed')),
  cookie: rt.String,
  access_features: rt.Array(rt.String),
});

export type GetVstoken = rt.Static<typeof GetVstokenSchema>;

const GetNonUserAccessSchema = rt.Record({
  status: rt.Union(rt.Literal('success'), rt.Literal('failed')),
  vstoken_cookie: rt.String,
  uuid: rt.String,
  access_features: rt.Array(rt.String),
});

export type GetNonUserAccess = rt.Static<typeof GetNonUserAccessSchema>;

const mapCheckForAccessToUserData = (
  data: rt.Static<typeof CheckAccessForPaperSchema>
): Pick<UserAttributes, 'access'> => ({ access: data.access_features });

const fetchCheckForAccess = (
  siteDomain: string,
  accessFeatures: AccessFeatures
) => {
  return schemaVerifiedFetch(
    CheckAccessForPaperSchema,
    `/api/jupiter/v1/check_access_for_paper/${siteDomain}/?require=any&access_features=${accessFeatures.join(
      ','
    )}`
  )
    .then((data) => {
      return {
        ttl: data.access === 'granted' ? 1000 * 10 : 0, // 10 seconds
        data: data,
      };
    })
    .catch((error) => {
      if (error instanceof IgnoredNetworkError) {
        return { ttl: -1, data: {} as never };
      }
      if (error instanceof rt.ValidationError) {
        log.error(
          `Jupiter - CheckAccessForPaperSchema, Runtype check failed. ${
            error.name
          }: ${JSON.stringify(error.details)}`,
          { error }
        );
      }
      throw error;
    });
};

export const getCheckForAccess = (
  siteDomain: string,
  accessFeatures: AccessFeatures
): Promise<Pick<UserAttributes, 'access'>> => {
  return getOrUpdate('getCheckForAccess', CheckAccessForPaperSchema, () =>
    fetchCheckForAccess(siteDomain, accessFeatures)
  ).then(mapCheckForAccessToUserData);
};

export const getUserAccess = (siteDomain: string): Promise<GetVstoken> => {
  return schemaVerifiedFetch(
    GetVstokenSchema,
    `/api/jupiter/v1/get_vstoken/${siteDomain}`,
    {
      method: 'post',
    }
  ).catch((error) => {
    if (error instanceof IgnoredNetworkError) {
      // TODO We should possibly hang here (returning a never-resolving promise)
      return {
        status: 'failed',
        access_features: [],
        cookie: null,
      };
    }
    if (error instanceof rt.ValidationError) {
      log.error(
        `Jupiter - GetVstokenSchema, Runtype check failed. ${
          error.name
        }: ${JSON.stringify(error.details)}`,
        error
      );
    }
    throw error;
  });
};

export const getNonUserAccess = (
  siteDomain: string
): Promise<GetNonUserAccess> => {
  return schemaVerifiedFetch(
    GetNonUserAccessSchema,
    `/api/jupiter/v1/non_user_access/${siteDomain}`,
    {
      method: 'post',
    }
  ).catch((error) => {
    if (error instanceof IgnoredNetworkError) {
      return {
        status: 'failed',
        access_features: [],
        vstoken_cookie: '',
        uuid: '',
      };
    }
    if (error instanceof rt.ValidationError) {
      log.error(
        `Jupiter - GetNonUserAccessSchema, Runtype check failed. ${
          error.name
        }: ${JSON.stringify(error.details)}`,
        error
      );
    }
    throw error;
  });
};

export const broadcastAccess = (): Promise<boolean> =>
  fetch(`/api/jupiter/v1/access/broadcast`, {
    method: 'POST',
  }).then((response) => {
    if (!response.ok) {
      throw new Error(
        `Request not ok (${response.status}: ${response.statusText}).`
      );
    }
    return true;
  });
