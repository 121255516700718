const queue: {
  [key: string]: {
    resolve: (arg0?: unknown) => void;
    reject: (reason?: Error) => void;
  }[];
} = {};

const add = (
  key: string,
  promiseCallbacks: {
    resolve: (arg0?: unknown) => void;
    reject: (reason?: Error) => void;
  }
): void => {
  if (!queue[key]) {
    queue[key] = [];
  }
  queue[key].push(promiseCallbacks);
};

const getAll = (key: string) => {
  const keyQueue = queue[key];
  if (!keyQueue) {
    return [];
  }
  delete queue[key];
  return keyQueue;
};

const peekQueueSize = (key: string): number => queue[key]?.length || 0;

export default { add, getAll, peekQueueSize };
