const getCookieValue = (
  nameEQ: string,
  c: string
): undefined | null | string | { [index: string]: unknown } => {
  const value = c.substring(nameEQ.length, c.length);

  if (value.substring(0, 1) === '{') {
    return JSON.parse(value);
  }

  if (value.substring(0, 3) === '%7B') {
    return JSON.parse(decodeURIComponent(value));
  }

  if (value === 'undefined') {
    return undefined;
  }

  if (value === '' || value === 'null') {
    return null;
  }

  return value;
};

export const getCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');

  for (let i = ca.length - 1; i >= 0; i -= 1) {
    const c = ca[i].trim();
    if (c.indexOf(nameEQ) === 0) {
      return getCookieValue(nameEQ, c);
    }
  }
  return null;
};

const setValidTime = (date: Date, validTimeDateOrDays?: Date | number) => {
  if (!validTimeDateOrDays) {
    return '';
  }

  if (validTimeDateOrDays instanceof Date) {
    return `; expires=${validTimeDateOrDays.toUTCString()}`;
  }
  /* Assume validTime is given in days */
  date.setTime(date.getTime() + validTimeDateOrDays * 24 * 60 * 60 * 1000);
  return `; expires=${date.toUTCString()}`;
};

const setValue = (value: unknown): string => {
  if (!value || value === '') {
    return '';
  }
  if (value instanceof Object) {
    return encodeURIComponent(JSON.stringify(value));
  }

  if (typeof value === 'number') {
    return value.toString(10);
  }
  return <string>value;
};

export function setCookie(
  name: string,
  value?: unknown,
  validTimeInDateOrDays: Date | number = Date.now(),
  path = '/',
  secure = false
) {
  const date = new Date();

  let cookiestring = `${name}=`;

  /* If value is empty or not set, handle as a cookie delete */
  if (!value || value === '') {
    validTimeInDateOrDays = -1;
  }

  cookiestring += setValue(value);
  cookiestring += setValidTime(date, validTimeInDateOrDays);
  cookiestring += `; path=${path}`;

  if (secure) {
    cookiestring += '; secure';
  }

  document.cookie = cookiestring;

  if (
    validTimeInDateOrDays &&
    validTimeInDateOrDays !== -1 &&
    getCookie(name) === null
  ) {
    /**
     * IE has a mode where user can choose to only accept session cookies, if we were unable to set it with
     * expires, we need to try setting it without expires to trigger setting it as session cookie for these users.
     */
    document.cookie = cookiestring.replace(/; expires=[^;]*/, '');
  }
  return cookiestring;
}

export function removeCookie(name: string): void {
  setCookie(name);
}

/**
 * This function should only be used in tests, as it will remove all cookies a user has on the domain.
 */
export function removeAllCookies() {
  const cookies = document.cookie.split(';');

  cookies.forEach((cookieToDelete) => {
    const eqPos = cookieToDelete.indexOf('=');
    removeCookie(eqPos > -1 ? cookieToDelete.substr(0, eqPos) : cookieToDelete);
  });
}
