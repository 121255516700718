import * as rt from 'runtypes';

import log from '../utils/log';
import getOrUpdate from '../utils/getOrUpdate';
import { UserAttributes } from '../types';
import { schemaVerifiedFetch } from '../utils/fetcher';
import ProblemJsonError from '../exceptions/ProblemJsonError';
import { getCurrentSiteDomain } from '../utils/url';

const PrivacyPreferencesSchema = rt.Record({
  allow_research_usage: rt.Boolean,
  personalized_content: rt.Boolean,
});

const SiteAccessSchema = rt.Record({
  site_domain: rt.String,
  access_features: rt.Array(rt.String),
});

const ServiceUserSchema = rt.Partial({
  uuid: rt.String,
  name: rt.String,
  tracking_key: rt.String,
  avatar: rt.String,
  access: rt.Array(SiteAccessSchema),
  privacy_preferences: PrivacyPreferencesSchema,
});

const mapUserToUserAttributes = (
  user: rt.Static<typeof ServiceUserSchema>
): Partial<UserAttributes> => {
  return {
    uuid: user.uuid || null,
    name: user.name || null,
    trackingKey: user.tracking_key || null,
    avatar: user.avatar || null,
    access: user.access
      ? user.access.filter(
          (siteAccess) => siteAccess.site_domain == getCurrentSiteDomain()
        )?.[0]?.access_features || []
      : null,
    privacyPreferences: user.privacy_preferences
      ? {
          allowResearchUsage: user.privacy_preferences.allow_research_usage,
          personalizedContent: user.privacy_preferences.personalized_content,
        }
      : null,
  };
};

const UserProblemSchema = rt.Record({
  type: rt.String,
  title: rt.String,
  detail: rt.String,
  status: rt.Number,
  instance: rt.Optional(rt.String),
});

const resolveUserProblem = async (problemJsonError: ProblemJsonError) => {
  let problem: rt.Static<typeof UserProblemSchema>;

  try {
    problem = UserProblemSchema.check(problemJsonError.problem);
  } catch (error) {
    if (error instanceof rt.ValidationError) {
      log.warn(
        `aID Users API - UserProblemSchema, Runtype check failed. ${
          error.name
        }: ${JSON.stringify(error.details)}`,
        error
      );
    }
    throw error;
  }

  switch (problem.type.replace(location.origin, '')) {
    // resolve without logging
    case '/api/problems/aid/session/not-found':
    case '/api/problem/aid/user/deactivated':
      return Promise.resolve(); // User is logged out
    default:
      throw new Error(
        `aID Users API - Internal Server Error. ${JSON.stringify(problem)}`
      );
  }
};

const fetchCurrentUser = () =>
  schemaVerifiedFetch(
    ServiceUserSchema,
    `/api/aid/users/self?filter=(uuid,name,tracking_key,access,privacy_preferences)`,
    {
      credentials: 'include',
    }
  )
    .then((data) => ({ ttl: 10000, data }))
    .catch(async (error) => {
      if (error instanceof rt.ValidationError) {
        log.error(
          `aID Users API - ServiceUserSchema, Runtype check failed. ${
            error.name
          }: ${JSON.stringify(error.details)}`,
          error
        );
        throw error;
      }
      if (error instanceof ProblemJsonError) {
        await resolveUserProblem(error);
        return { ttl: -1, data: {} };
      }
      throw error;
    });

export const getCurrentUser = async (): Promise<Partial<UserAttributes>> => {
  return getOrUpdate(
    'getCurrentUser',
    ServiceUserSchema,
    fetchCurrentUser
  ).then((currentUser) => mapUserToUserAttributes(currentUser));
};
