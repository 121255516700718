import log from './log';
import getGlobalThis from './getGlobalThis';

export const isRunningOnLocalhost = (): boolean => {
  if (getGlobalThis().location.hostname === 'localhost') {
    log.error('@amedia/user cannot work on localhost.');
    return true;
  }
  return false;
};
