export default function <T>(promises: Array<Promise<T>>) {
  return Promise.all(
    promises.map((p) => {
      return p
        .then((value) => {
          return {
            status: 'fulfilled',
            value,
          };
        })
        .catch((reason) => {
          return {
            status: 'rejected',
            reason,
          };
        });
    })
  );
}
