import UserDataRequest from '../../lib/UserDataRequest';
import store from '../../store';

export async function removeUserStorage() {
  new UserDataRequest().subscribe(({ state }) => {
    // Need to explicitly check for false here until we have then()
    if (state.isLoggedIn === false) {
      store.set('userAttributes', {});
      store.set('storage', {});
    }
  });
}
