import { getCookie } from '../lib/cookie';

type VSTOKEN2 = {
  signature: string;
  validTo: Date;
  accessFeatures: Array<string>;
  customer: string;
  site: string;
  source: string;
};

export const readAndParseVSTOKEN2Cookie = (): VSTOKEN2 => {
  const cookie = getCookie('VSTOKEN2');
  if (!cookie || typeof cookie !== 'string') {
    return null;
  }
  const [signature, validTo, accessFeatures, customer, site, source] =
    cookie.split(':');
  return {
    signature,
    validTo: new Date(parseInt(`${validTo}000`, 10)),
    accessFeatures: accessFeatures.split(',').filter((a) => !!a),
    customer,
    site,
    source,
  };
};
