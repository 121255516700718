import { merge as deepmerge } from 'ts-deepmerge';

/** Naive implementation of deep copy needed for copying parameters when
 * merging multiple calls to perform.
 *
 * @param obj object to copy deeply
 */
function deepCopy<T>(obj: T): T {
  if (typeof obj === 'string' || obj instanceof String) {
    return `${obj}` as unknown as T;
  }
  if (obj instanceof Array) {
    return [...obj].map(deepCopy) as unknown as T;
  }
  return Object.entries(obj).reduce(
    (copy, [k, v]) => ({
      ...copy,
      [k]: deepCopy(v),
    }),
    {}
  ) as T;
}

/**
 * The Debouncer can be used when we want a clean way to say 'I want to fetch this and that, and once it's done,
 * resolve my promise', but instead of the fetch being performed right away, we want to wait for more users to
 * request other stuff. This will keep us from performing lots of individual fetches to the backend.
 *
 * Our main use case is a standard site front page where one component on the page requests the euucp namespace
 * from Europa, and then another component requests the privacy_preferences namespace. Instead of the Europa client
 * calling two fetches to the server, one for euucp and another for privacy_preferences, we want the Debouncer to
 * aggregate these calls into one call to the backend, that requests both euucp AND privacy_preferences, so the response
 * to both components will be both namespaces (which the Europa client can filter before it actually reaches the
 * component).
 */
class Debouncer<ParamsType, ResultType> {
  private timer: ReturnType<typeof setTimeout> | undefined;

  private params: ParamsType;

  private queue: {
    resolve: (arg0: unknown) => void;
    reject: (reason?: Error) => void;
  }[] = [];

  constructor(
    // The number of milliseconds to wait for new calls to perform before actually performing workload
    private timeoutMs: number,
    // Default parameters if none provided, normally an empty object or list
    private defaultParams: ParamsType,
    // Function that can accumulate parameters from successive calls to perform, see examples below
    private paramAccumulator: (
      previousParams: ParamsType,
      newParams: ParamsType
    ) => ParamsType,
    // The workload we want to perform on accumulated parameters once timeout has been reached
    private workload: (params: ParamsType) => Promise<ResultType>
  ) {
    this.params = deepCopy(defaultParams);
  }

  // Caller indicates that it wants to perform workload on a set of parameters. We will wait this.timeoutMs milliseconds
  // and see if we get more calls to perform workload, if we do, we accumulate parameters and wait this.timeoutMs

  // Accumulate parameters as lists of strings
  static stringListParamsAccumulator(
    accumulatedParams: string[],
    newParams: string[]
  ) {
    newParams.forEach((param) => {
      if (!accumulatedParams.includes(param)) {
        accumulatedParams.push(param);
      }
    });
    return accumulatedParams;
  }

  // Accumulate parameters as map with string keys
  static stringMapParamsAccumulator(
    previousParams: Record<string, unknown>,
    newParams: Record<string, unknown>
  ) {
    return deepmerge(previousParams, newParams);
  }

  // more milliseconds until we finally perform workload and resolve promises.
  perform(newParams: ParamsType): Promise<ResultType> {
    return new Promise((resolve, reject) => {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.params = this.paramAccumulator(this.params, newParams);
      this.queue.push({ resolve, reject });
      this.timer = setTimeout(async () => {
        // Copy current state to use for performing workload and resolving promises after workload completes
        const params = deepCopy(this.params);
        const promisesToKeep = [...this.queue];

        // Setup new clean state for future calls to perform
        this.params = this.defaultParams;
        this.queue = [];

        // Perform workload and resolve promises for all callers with result
        this.workload(params)
          .then((result) => {
            promisesToKeep.forEach((promise) => {
              promise.resolve(result);
            });
          })
          .catch((e) => {
            // If workload fails, we need to reject all promises so callers won't be left hanging
            promisesToKeep.forEach((promise) => {
              promise.reject(e);
            });
          });
      }, this.timeoutMs);
    });
  }
}

export default Debouncer;
