import { getActiveEmergencyModes } from '../utils/emergencyMode';

export default class EmergencyModeError extends Error {
  public activeEmergencyModes: string[];

  constructor(message?: string) {
    const activeEmergencyModes = getActiveEmergencyModes();
    if (!message) {
      super(message);
    } else {
      super(`Platform is in emergency mode: ${activeEmergencyModes}`);
    }
    this.activeEmergencyModes = activeEmergencyModes;
  }
}
