import store from '../store';
import { dispatch, listenWithExclusivity } from '../utils/customEventWrapper';
import { requestStorage, saveStorage, updateStorage } from '../actions/storage';
import { whenIsLoggedIn } from '../utils/loginState';
import { ClientId } from '../clients/europa';

import { AmediaUserEvents } from './eventMap';

export type NamespaceList = Array<string>;
export type Storage = Record<ClientId, Record<string, Record<string, string>>>;
export type StorageRequest = Record<ClientId, NamespaceList>;

const onExclusiveAmediaUserEventAndIsLoggedIn = <
  T extends keyof WindowEventMap,
>(
  event: T,
  listener: (evt: WindowEventMap[T]) => void
) =>
  listenWithExclusivity(event, (evt) => {
    whenIsLoggedIn().then(() => listener(evt));
  });

function getMissingStorage(
  requestedStorage: Record<ClientId, NamespaceList>
): Partial<typeof requestStorage> {
  const currentStorage = store.get('storage') || {};
  // If we have some data already dispatch it right away
  if (Object.keys(currentStorage).length > 0) {
    dispatch(
      new CustomEvent(AmediaUserEvents.ON_STORAGE_UPDATED, {
        detail: currentStorage,
      })
    );
  }
  return Object.keys(requestedStorage).reduce(
    (missingStorage: Partial<typeof requestedStorage>, clientId) => {
      if (!currentStorage[clientId]) {
        missingStorage[clientId] = requestedStorage[clientId];
      } else {
        const missingNamespaces = requestedStorage[clientId].filter(
          (namespace) => !currentStorage[clientId][namespace]
        );
        if (missingNamespaces.length > 0) {
          missingStorage[clientId] = missingNamespaces;
        }
      }
      return missingStorage;
    },
    {}
  );
}

export const listenForStorageRequests = () => {
  store.on('storage', (storage) => {
    dispatch(
      new CustomEvent(AmediaUserEvents.ON_STORAGE_UPDATED, { detail: storage })
    );
  });

  onExclusiveAmediaUserEventAndIsLoggedIn(
    AmediaUserEvents.REQUEST_STORAGE_NAMESPACES,
    (evt) => {
      const missingStorage = getMissingStorage(evt.detail);
      if (Object.keys(missingStorage).length > 0) {
        requestStorage(missingStorage);
      }
    }
  );

  onExclusiveAmediaUserEventAndIsLoggedIn(
    AmediaUserEvents.UPDATE_STORAGE,
    (evt) => {
      updateStorage(evt.detail);
    }
  );

  onExclusiveAmediaUserEventAndIsLoggedIn(
    AmediaUserEvents.SAVE_STORAGE,
    (evt) => {
      saveStorage(evt.detail);
    }
  );
};
