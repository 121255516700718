import { dispatch, listenWithExclusivity } from '../utils/customEventWrapper';
import store from '../store';

import { AmediaUserEvents } from './eventMap';

export const listenForStateRequests = () => {
  listenWithExclusivity(AmediaUserEvents.REQUEST_STATE, () => {
    const state = store.get('state');
    if (state && Object.keys(state).length > 0) {
      dispatch(
        new CustomEvent(AmediaUserEvents.ON_STATE_UPDATED, {
          detail: state,
        })
      );
    }
    store.on('state', (updatedState) => {
      dispatch(
        new CustomEvent(AmediaUserEvents.ON_STATE_UPDATED, {
          detail: updatedState,
        })
      );
    });
  });
};
