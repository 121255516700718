import { listenWithExclusivity } from '../utils/customEventWrapper';
import { refreshData } from '../actions/refreshData';

import { AmediaUserEvents } from './eventMap';

export const listenForDataRefreshRequest = () => {
  listenWithExclusivity(AmediaUserEvents.REQUEST_DATA_REFRESH, () => {
    refreshData();
  });
};
listenForDataRefreshRequest();
