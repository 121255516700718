import ResponseError from './ResponseError';

export type ProblemJson = {
  type: string;
  title: string;
  detail: string;
  status: number;
};

export default class ProblemJsonError extends ResponseError {
  public problem: ProblemJson;
  public response: Response;

  constructor(body: string, response: Response) {
    super(body, response);
    this.problem = JSON.parse(body);
  }
}
