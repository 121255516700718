import { listenWithExclusivity } from '../utils/customEventWrapper';
import { refreshData } from '../actions/refreshData';
import getGlobalThis from '../utils/getGlobalThis';

import { AmediaUserEvents } from './eventMap';

const focusListener: EventListener = () => {
  refreshData();
};
let isBound = false;

export const listenForFocus = () => {
  listenWithExclusivity(AmediaUserEvents.ON_FOCUS, () => {
    if (isBound) {
      return;
    }
    isBound = true;
    getGlobalThis().addEventListener('focus', focusListener);
  });
  getGlobalThis().dispatchEvent(new CustomEvent(AmediaUserEvents.ON_FOCUS));
};

export const reset = () => {
  if (isBound) {
    getGlobalThis().removeEventListener('focus', focusListener);
    isBound = false;
  }
  listenForFocus();
};

listenForFocus();
