import { requestAttributes } from '../actions/userData';
import { dispatch, listenWithExclusivity } from '../utils/customEventWrapper';
import store from '../store';

import { AmediaUserEvents } from './eventMap';

export const listenForUserAttributeRequests = () => {
  store.on('userAttributes', (user) => {
    dispatch(
      new CustomEvent(AmediaUserEvents.ON_ATTRIBUTES_UPDATED, {
        detail: user,
      })
    );
  });
  listenWithExclusivity(AmediaUserEvents.REQUEST_USER_ATTRIBUTES, (evt) => {
    const requestedAttributes = evt.detail;
    const currentAttributes = store.get('userAttributes') || {};
    if (Object.keys(currentAttributes).length > 0) {
      dispatch(
        new CustomEvent(AmediaUserEvents.ON_ATTRIBUTES_UPDATED, {
          detail: currentAttributes,
        })
      );
    }
    const missingAttributes = requestedAttributes.filter(
      (attribute) => currentAttributes[attribute] === undefined
    );
    if (missingAttributes.length > 0) {
      requestAttributes(missingAttributes);
    }
  });
};
