import { getPrimarySite, PrimarySite } from '../clients/shamo';
import { attemptPaywallAutoLogin } from '../autologin';
import { broadcastAccess } from '../clients/jupiter';
import { getCurrentAccess, hasAccess } from '../actions/authorization';
import { isRunningOnLocalhost } from '../utils/localhostCheck';

import UserDataRequest from './UserDataRequest';

export type SiteAccess = {
  denied: boolean;
  granted: boolean;
  accessFeatures: string[];
};

export type SiteAccessResponse = {
  isLoggedIn: boolean;
  hasAccess: boolean;
  primarySite?: null | PrimarySite;
  accessFeatures: string[];
};

export async function verifyIsLoggedIn(): Promise<boolean> {
  return new UserDataRequest()
    .withAttributes(['name'])
    .fetch()
    .then(
      ({ state: { isLoggedIn } }) =>
        isLoggedIn || attemptPaywallAutoLogin().then(() => false)
    );
}

async function resolveCurrentAccess(
  siteDomain: string,
  requiredAccessFeatures: Array<string>
): Promise<SiteAccess> {
  const current = await getCurrentAccess(siteDomain);
  const granted = hasAccess(current.accessFeatures, [
    ...requiredAccessFeatures,
  ]);
  return {
    granted,
    denied: !granted,
    accessFeatures: current.accessFeatures,
  };
}

export default class SiteAccessRequest {
  private siteDomain: string;

  constructor(private requiredAccessFeatures: Array<string> = []) {
    if (!Array.isArray(this.requiredAccessFeatures)) {
      throw new TypeError('requiredAccessFeatures must be an array');
    }
  }

  public withSiteDomain(siteDomain: string): this {
    if (typeof siteDomain !== 'string') {
      throw new TypeError('siteDomain must be of type string');
    }
    this.siteDomain = siteDomain;
    return this;
  }

  /**
   * @deprecated Required now. Set in constructor
   */
  public withAccessFeatures(accessFeatures: Array<string>): this {
    if (!Array.isArray(accessFeatures)) {
      throw new TypeError('accessFeatures must be an array');
    }
    this.requiredAccessFeatures = accessFeatures;
    return this;
  }

  public async requestAccess(): Promise<SiteAccessResponse> {
    if (isRunningOnLocalhost()) {
      return Promise.reject(
        new Error(
          `@amedia/user cannot request site access for (${this.siteDomain}) on localhost`
        )
      );
    }

    // May attempt to recover session (reload / send through redirect loop)
    if (!(await verifyIsLoggedIn())) {
      return { isLoggedIn: false, hasAccess: false, accessFeatures: [] };
    }

    const current = await resolveCurrentAccess(
      this.siteDomain,
      this.requiredAccessFeatures
    );
    if (current.denied) {
      const primarySite = await getPrimarySite(this.requiredAccessFeatures);
      return {
        isLoggedIn: true,
        hasAccess: false,
        primarySite,
        accessFeatures: current.accessFeatures,
      };
    }

    await broadcastAccess();
    return {
      isLoggedIn: true,
      hasAccess: true,
      accessFeatures: current.accessFeatures,
    };
  }
}
