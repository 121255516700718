import * as rt from 'runtypes';

import getOrUpdate from '../utils/getOrUpdate';
import { UserAttributes } from '../types';
import log from '../utils/log';
import { schemaVerifiedFetch } from '../utils/fetcher';
import IgnoredNetworkError from '../exceptions/IgnoredNetworkError';

const InfoSchema = rt.Dictionary(rt.Unknown, rt.String).And(
  rt.Partial({
    ad_segments: rt.String,
    ad_segments_user: rt.String,
    amedia_employee: rt.Boolean,
    amedia_employee_user: rt.Boolean,
    age: rt.Number,
    age_user: rt.Number,
    birth_year: rt.String,
    birth_year_user: rt.String,
    gender: rt.String,
    gender_user: rt.String,
    grunnkrets: rt.String,
    grunnkrets_user: rt.String,
    postcode: rt.String,
    postcode_user: rt.String,
    shared_account: rt.Boolean,
    shared_account_user: rt.Boolean,
    user_grouping_number: rt.String,
    user_grouping_number_user: rt.String,
  })
);

const getNebulaData = (
  siteDomain: string,
  browserId: string,
  trackingKey?: string
) => {
  const url = new URL('https://services.gcloud.api.no/api/nebula/v1/info');
  url.searchParams.set('domain', siteDomain);
  url.searchParams.set('browser_id', browserId);
  if (trackingKey) {
    /**
     * Legacy alert!
     * Tracking key (what we use to track what the user is doing) was previously called user_key. This caused confusion
     * with user_uuid (which is the real user id). user_key was since renamed
     * to tracking_key  to
     * better separate the two, but nebula still likes the old name
     */
    url.searchParams.set('user_key', trackingKey);
  }
  // Browser ID can possibly be empty (if browser rejects cookie write) and trackingKey only exists when someone is logged in.
  if (!browserId && !trackingKey) {
    return Promise.resolve({
      ttl: -1,
      data: {},
    });
  }

  return schemaVerifiedFetch(InfoSchema, url.href)
    .then((data) => ({
      ttl: 1000 * 60 * 5, // cache 5 min
      data,
    }))
    .catch((error) => {
      if (error instanceof IgnoredNetworkError) {
        return { ttl: -1, data: {} };
      }
      if (error instanceof rt.ValidationError) {
        log.error(
          `Nebula - InfoSchema, Runtype check failed. ${
            error.name
          }: ${JSON.stringify(error.details)}`,
          error
        );
        //return { ttl: -1, data: {} };
      }
      throw error;
    });
};

const mapNebulaToUserData = (
  data: rt.Static<typeof InfoSchema>
): Pick<UserAttributes, 'extraData'> => ({
  extraData: data,
});

export const getInfo = (
  siteDomain: string,
  browserId: string,
  trackingKey?: string
): Promise<Pick<UserAttributes, 'extraData'>> =>
  getOrUpdate('getNebulaInfo', InfoSchema, () =>
    getNebulaData(siteDomain, browserId, trackingKey)
  ).then(mapNebulaToUserData);
