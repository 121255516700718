import { merge as deepmerge } from 'ts-deepmerge';

import { State, UserAttributes } from '../types';

type StoreDataMap = {
  userAttributes: Partial<UserAttributes>;
  state: State;
  storage: {
    [client: string]: { [namespace: string]: { [index: string]: unknown } };
  };
  internalState: {
    isPoisoned: boolean;
    lastEmergencyModeReportTimestamp?: number;
    requestedAttributes: Set<keyof UserAttributes>;
    requestedStorage: { [client: string]: Array<string> };
    isCMPDialogOpen: boolean;
    paywallUnlockRequested: boolean;
  };
};

type Listener<T> = (data: T) => void;
type Listeners<T = StoreDataMap> = {
  [key in keyof T]?: Array<Listener<T[key]>>;
};

const store: Partial<StoreDataMap> = {
  internalState: {
    isPoisoned: false,
    requestedAttributes: new Set(),
    requestedStorage: {},
    isCMPDialogOpen: false,
    paywallUnlockRequested: false,
  },
};
const listeners: Listeners = {};

const emit = (key: keyof StoreDataMap): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  listeners[key]?.forEach((listener) => listener(store[key]));
};

const on = <K extends keyof StoreDataMap>(
  key: K,
  listener: Listener<StoreDataMap[K]>
) => {
  if (!listeners[key]) {
    listeners[key] = [];
  }

  listeners[key].push(listener);
};

const set = (key: keyof StoreDataMap, value: StoreDataMap[typeof key]) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  store[key] = value;
  emit(key);
};

const merge = <K extends keyof StoreDataMap>(
  key: K,
  value: Partial<StoreDataMap[K]>
) => {
  set(key, deepmerge(store[key] || {}, value) as unknown as StoreDataMap[K]);
};

const get = <K extends keyof StoreDataMap>(
  key: K
): Partial<StoreDataMap[K]> | undefined => store[key];

const clear = () => {
  store.state = undefined;
  store.userAttributes = undefined;
  store.storage = undefined;
  store.internalState = {
    isPoisoned: false,
    requestedAttributes: new Set(),
    requestedStorage: {},
    isCMPDialogOpen: false,
    paywallUnlockRequested: false,
  };
  Object.keys(listeners).forEach((key: keyof Listeners) => {
    listeners[key] = [];
  });
};

export default { merge, set, get, on, clear };
