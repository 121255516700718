import store from '../store';
import { fetcher } from '../utils/fetcher';
import ResponseError from '../exceptions/ResponseError';

export const removeSession = async (domain: string): Promise<void> =>
  fetcher(`/api/pluto/v1/sessions/current?publication_domain=${domain}`, {
    method: 'DELETE',
  })
    .then(() => {
      store.merge('state', { isLoggedIn: false });
    })
    .catch((error) => {
      if (error instanceof ResponseError) {
        if (error.response.status === 403 || error.response.status === 404) {
          // No session to log out. Ignoring
          return;
        }
      }
      throw new Error(
        `Error, could not perform deleteRequest. Status: ${error.response.status} Text: ${error.response.statusText}`
      );
    });
