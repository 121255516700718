import { getNonUserAccess, getUserAccess } from '../clients/jupiter';
import { getCurrentUrl } from '../utils/url';
import { getCookie, setCookie } from '../lib/cookie';
import log from '../utils/log';
import Debouncer from '../utils/debouncer';

type SetCookieResponse = { success: boolean; accessFeatures: string[] };

export const hasAccess = (
  currentAccessFeatures: string[],
  requestedAccessFeatures: string[]
): boolean =>
  (requestedAccessFeatures.length < 1 && currentAccessFeatures.length > 0) ||
  requestedAccessFeatures.filter((requestedAccessFeature) =>
    currentAccessFeatures.includes(requestedAccessFeature)
  ).length > 0;

export const setUserAccessCookie = async (
  siteDomain: string
): Promise<SetCookieResponse> => {
  const vstoken = await getUserAccess(siteDomain);
  if (vstoken.status === 'success') {
    /**
     * The VSTOKEN cookie will live for 365 days, but a timestamp in the payload will ensure that it cannot
     * be used to gain access for that long. The reason we want the cookie to live long is that it's also
     * used to keep subscribers from seeing ads for buying the subscription they already have.
     */
    setCookie('VSTOKEN', vstoken.cookie, 365, '/');
  }
  return {
    success: vstoken.status === 'success',
    accessFeatures: vstoken.access_features,
  };
};

const setNonUserAccessCookieDebouncerMap: Record<
  string,
  Debouncer<string, SetCookieResponse>
> = {};
export const setNonUserAccessCookie = async (
  siteDomain: string
): Promise<SetCookieResponse> => {
  if (!setNonUserAccessCookieDebouncerMap[siteDomain]) {
    setNonUserAccessCookieDebouncerMap[siteDomain] = new Debouncer(
      50,
      siteDomain,
      () => siteDomain,
      async (siteDomain) => {
        const vstoken2 = await getNonUserAccess(siteDomain);
        if (vstoken2.status === 'success') {
          setCookie('VSTOKEN2', vstoken2.vstoken_cookie, 1, '/');
        }
        return {
          success: vstoken2.status === 'success',
          accessFeatures: vstoken2.access_features,
        };
      }
    );
  }
  return setNonUserAccessCookieDebouncerMap[siteDomain].perform(null);
};

export const setAccessCookies = async (
  siteDomain: string
): Promise<SetCookieResponse> => {
  const setters: Array<Promise<SetCookieResponse>> = [];
  setters.push(
    setUserAccessCookie(siteDomain).catch(() =>
      Promise.resolve({ success: false, accessFeatures: [] })
    )
  );
  setters.push(
    setNonUserAccessCookie(siteDomain).catch(() =>
      Promise.resolve({ success: false, accessFeatures: [] })
    )
  );
  return Promise.all(setters).then((results) =>
    results.reduce(
      (accumulatedResult, result) => ({
        success: accumulatedResult.success && result.success,
        accessFeatures: accumulatedResult.accessFeatures
          .concat(result.accessFeatures)
          .filter((v, i, a) => a.indexOf(v) === i) // Remove duplicates by keeping only first copy
          .sort(), // We want to keep result deterministic for easy testing
      }),
      {
        success: true,
        accessFeatures: [],
      }
    )
  );
};

export const getCurrentAccess = async (siteDomain: string) => {
  try {
    const [userAccess, nonUserAccess] = await Promise.all([
      getUserAccess(siteDomain),
      getNonUserAccess(siteDomain),
    ]);
    return {
      success: [userAccess.status, nonUserAccess.status].every(
        (s) => s === 'success'
      ),
      accessFeatures: [
        ...new Set([
          ...userAccess.access_features,
          ...nonUserAccess.access_features,
        ]),
      ].sort(),
    };
  } catch (error) {
    log.error(`Unable to resolve access for site (${siteDomain})`, { error });
    throw new Error(`Unable to resolve access for site (${siteDomain})`);
  }
};

export const setHasPerformedPaywallReload = (requestedUrl?: string): void => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 2 * 60 * 1000); // Add two minutes

  setCookie(
    'aid-paywall-reload-performed',
    requestedUrl || getCurrentUrl(),
    expires
  );
};

export const hasRecentlyPerformedPaywallReload = (
  requestedUrl?: string
): boolean =>
  getCookie('aid-paywall-reload-performed') ===
  (requestedUrl || getCurrentUrl());
