class PaywallError extends Error {
  static ERROR_RELOADED_NO_ACCESS = 'RELOADED_NO_ACCESS';

  static ERROR_ENABLING_ACCESS_FAILED = 'ENABLING_ACCESS_FAILED';

  static ERROR_NO_ACCESS_AFTER_SUCCESSFUL_ACTIVATION_WITH_REQUESTED_ACCESS_FEATURES =
    'NO_ACCESS_AFTER_SUCCESSFUL_ACTIVATION_WITH_REQUESTED_ACCESS_FEATURES';

  public code: string;

  constructor(message: string, code: string) {
    super(message);
    this.code = code;
  }
}

export default PaywallError;
