// eslint-disable-next-line import/no-unresolved
import browserlogger from '@amedia/browserlog';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { version } from '../../package.json';

import { isInEmergencyMode } from './emergencyMode';

const browserlog = browserlogger.child({
  component: '@amedia/user',
  component_version: version,
});

export type Logger = {
  debug: (message: string | Error, context?: unknown) => void;
  info: (message: string | Error, context?: unknown) => void;
  warn: (message: string | Error, context?: unknown) => void;
  error: (message: string | Error, context?: unknown) => void;
};

let logger: Logger;

export const setLogger = (engine: Logger) => {
  logger = engine;
};

const getLogger = () => logger || browserlog;

const rewriteResponseObject = async (
  response: Response
): Promise<Record<string, unknown>> => {
  const responseText = !response.bodyUsed
    ? await response.text()
    : 'Response body not available for logging';
  const headers: Record<string, string> = {};
  response.headers.forEach((value, key) => {
    headers[key] = value;
  });
  return {
    url: response.url,
    status: response.status,
    statusText: response.statusText,
    headers,
    body: responseText,
  };
};

function isResponse(param: unknown): param is Response {
  return param && param instanceof Response;
}

const processIncomingLogData = async (
  message: string | Error,
  context: unknown = {}
): Promise<{ message: string; context: unknown }> => {
  const internalMessage: string =
    typeof message === 'string' ? message : message.message;
  const internalContext: Record<string, unknown> = {
    origin: '@amedia/user',
    originVersion: version,
  };
  if (message instanceof Error) {
    internalContext.error = message;
  }

  if (context instanceof Error) {
    internalContext['error'] = context;
  } else if (context instanceof Response) {
    internalContext.response = await rewriteResponseObject(context);
  } else {
    Object.keys(context).forEach((key: keyof unknown) => {
      const currentContext = context[key];
      if (isResponse(currentContext)) {
        internalContext[key] = rewriteResponseObject(currentContext);
      } else {
        internalContext[key] = currentContext;
      }
    });
  }
  return {
    message: internalMessage,
    context: internalContext,
  };
};

const processAndLog = (
  level: keyof Logger,
  message: string | Error,
  context?: unknown
) => {
  if (isInEmergencyMode()) {
    // Omit logging when in emergency mode
    return;
  }
  processIncomingLogData(message, context).then(({ message: m, context: c }) =>
    getLogger()[level](m, c)
  );
};

export default <Logger>{
  debug: (message, context) => processAndLog('debug', message, context),
  info: (message, context) => processAndLog('info', message, context),
  warn: (message, context) => processAndLog('warn', message, context),
  error: (message, context) => processAndLog('error', message, context),
};
