import { RPCclient } from '@amedia/frontend-rpc';

interface LocalRPCclient extends RPCclient {
  userAuthenticated: (isLoggedIn: boolean) => void;
}

let rpcSessionClient: LocalRPCclient;
export const getRPCClient = (): LocalRPCclient => {
  if (rpcSessionClient) {
    return rpcSessionClient;
  }
  rpcSessionClient = new RPCclient({
    version: '1.0.0',
    component: `userdata-session`,
    methods: ['userAuthenticated'],
  }) as LocalRPCclient;

  return rpcSessionClient;
};
