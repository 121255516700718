import store from '../store';

export const whenLoginStateResolved = (): Promise<boolean> =>
  new Promise((resolve) => {
    const currentLoggedInState = store.get('state')?.isLoggedIn;
    if (currentLoggedInState === false || currentLoggedInState === true) {
      resolve(currentLoggedInState);
      return;
    }
    store.on('state', ({ isLoggedIn }) => {
      if (isLoggedIn === false || isLoggedIn === true) {
        resolve(isLoggedIn);
      }
    });
  });

export const whenIsLoggedIn = (): Promise<void> =>
  new Promise((resolve) =>
    whenLoginStateResolved().then((isLoggedIn) => {
      if (isLoggedIn) {
        resolve();
      }
    })
  );
