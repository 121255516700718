export default class ResponseError extends Error {
  public body: string;
  public response: Response;
  public nestedException: Error;

  constructor(body: string, response: Response, nestedException?: Error) {
    const message = `Request failed (${response.status}/${
      response.statusText
    }: ${response.url || '[missing url]'}) Body: ${
      body || '[empty body received]'
    }`;
    super(
      message + (nestedException ? `. Reason: ${nestedException.message}` : '')
    );
    this.body = body;
    this.response = response;
    this.nestedException = nestedException;
  }
}
