export const isFunction = (argument: unknown): argument is () => never =>
  !!argument && typeof argument === 'function';

export const isRecord = (
  argument: unknown
): argument is Record<string, unknown> =>
  !!argument && typeof argument === 'object';

export const isError = (argument: unknown): argument is Error =>
  !!argument && argument instanceof Error;

export const isString = (argument: unknown): argument is string =>
  !!argument && typeof argument === 'string';

export const isNumber = (argument: unknown): argument is number =>
  !!argument && typeof argument === 'number';

export const isBoolean = (argument: unknown): argument is boolean =>
  !!argument && typeof argument === 'boolean';
