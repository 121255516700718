import store from '../store/index';

import log from './log';
import getGlobalThis from './getGlobalThis';

let rootElement: Window | Element | typeof globalThis;

export const setRootElement = (element: typeof rootElement) => {
  rootElement = element;
};

setRootElement(getGlobalThis());

/**
 * This listener MUST be used by all listeners in ../eventHandlers
 * We want to ensure that only _one_ instance of this package actually
 * orchestrates data and performs requests against backends.
 * @param type
 * @param listener
 */
export const listenWithExclusivity = <T extends keyof WindowEventMap>(
  type: T,
  listener: (this: typeof rootElement, ev: WindowEventMap[T]) => void
) => {
  if (!rootElement) {
    log.error('No root element to bind event to!');
    return;
  }
  rootElement.addEventListener(type, (evt) => {
    // Since this lib might be loaded multiple times, we need to prevent all other processing of this event.
    evt.stopImmediatePropagation();

    /**
     * This will prevent any listeners from being called if we're poisoned,
     * thus preventing any external calls from being started when we're
     * being navigated away
     */
    if (store.get('internalState')?.isPoisoned) {
      return;
    }
    listener.apply(rootElement, [evt as WindowEventMap[T]]);
  });
};

/**
 * Use this for response listeners. (../index.ts) where we want
 * all updates with data - no matter who sent the actual request
 * @param type
 * @param listener
 */
export const listenForAllEvents = <T extends keyof WindowEventMap>(
  type: T,
  listener: (this: typeof rootElement, ev: WindowEventMap[T]) => void
) => {
  if (!rootElement) {
    log.error('No root element to bind event to!');
    return () => {
      // It's more straightforward to always return an "unsubscribe"-function.
    };
  }
  const eventListener = (evt: WindowEventMap[T]) => {
    listener.apply(rootElement, [evt]);
  };
  rootElement.addEventListener(type, eventListener);
  return () => {
    rootElement.removeEventListener(type, eventListener);
  };
};

export const dispatch = <T extends Event>(event: T) => {
  if (!rootElement) {
    log.error('No root element to dispatch event on!');
    return;
  }
  /**
   * This will prevent any further event dispatching.
   * Effectively having requesters of data wait "forever" (the few
   * milliseconds) until we navigate away
   */
  if (store.get('internalState')?.isPoisoned) {
    return;
  }
  rootElement.dispatchEvent(event);
};
