import * as rt from 'runtypes';
import { ValidationError } from 'runtypes';

import store from '../store';
import ServerError from '../exceptions/ServerError';
import IgnoredNetworkError from '../exceptions/IgnoredNetworkError';
import ResponseError from '../exceptions/ResponseError';
import ProblemJsonError from '../exceptions/ProblemJsonError';
import EmergencyModeError from '../exceptions/EmergencyModeError';

import log from './log';
import {
  EmergencyMode,
  getActiveEmergencyModes,
  isInEmergencyMode,
  lastEmergencyModeReportTimestamp,
} from './emergencyMode';

export const RETRY_DELAY = 2000; // Two seconds
export const EMERGENCY_MODE_RETRY_DELAY = 60000; // One minute

const isProblemJsonContentType = (contentType: string) =>
  /^application\/problem\+json/.test(contentType);

const shouldAttemptRetry = (response: Response) =>
  response.status >= 500 &&
  response.status <= 599 &&
  !isProblemJsonContentType(response.headers.get('content-type'));

function handleNetworkError(error: Error) {
  throw new IgnoredNetworkError(error);
}

function handleEmergencyMode(response: Response): Response {
  const emergencyMode = response.headers.get('x-emergency-mode')?.split(',');
  const lastEmergencyModeReportTimestamp =
    emergencyMode?.length > 0 ? new Date().getTime() : 0;
  store.merge('state', {
    emergencyMode,
  });
  store.merge('internalState', {
    lastEmergencyModeReportTimestamp,
  });
  return response;
}

function inEmergencyMode(): boolean {
  const e = getActiveEmergencyModes();
  return e.length > 0;
}

function serverErrorRetryHandler(
  url: RequestInfo,
  options: RequestInit,
  isRetry: boolean
): (response: Response) => Promise<Response> {
  return async (response) => {
    if (response.ok || inEmergencyMode() || !shouldAttemptRetry(response)) {
      return response;
    }
    if (isRetry) {
      log.error(`Request retry failed. (${url})`, {
        'http.status_code': response.status,
      });
      return Promise.reject(
        new ServerError('Backend server failed fetching data.')
      );
    }
    return await new Promise<Response>((resolve, reject) => {
      setTimeout(() => {
        log.warn(
          `Received 5xx response from server. Will attempt one retry. (${url})`,
          {
            'http.status_code': response.status,
          }
        );
        innerFetcher(url, options, true).then(resolve, reject);
      }, RETRY_DELAY);
    });
  };
}

async function unmarshalResponse(
  response: Response
): Promise<Record<string, unknown> | null> {
  const body = await response.text();
  if (body.length == 0) {
    return null;
  }
  if (/fetch failed/i.test(body)) {
    log.info('[DEBUG] Made it to unmarshalResponse', { body });
  }
  try {
    const data = JSON.parse(body);
    if (typeof data === 'undefined') {
      log.warn(`Response parsed as undefined from body (${body})`);
      return null;
    }
    return data;
  } catch (e) {
    if (e instanceof SyntaxError) {
      throw new ResponseError(body, response, e);
    }
    throw e;
  }
}

async function unmarshalResponseWithRawBody(
  response: Response
): Promise<{ rawBody: string; json: Record<string, unknown> | null }> {
  const body = await response.text();
  if (body.length == 0) {
    return { rawBody: body, json: null };
  }
  if (/fetch failed/i.test(body)) {
    log.info('[DEBUG] Made it to unmarshalResponse', { body });
  }
  try {
    const data = JSON.parse(body);
    if (typeof data === 'undefined') {
      log.warn(`Response parsed as undefined from body (${body})`);
      return { rawBody: body, json: null };
    }
    return { rawBody: body, json: data };
  } catch (e) {
    if (e instanceof SyntaxError) {
      throw new ResponseError(body, response, e);
    }
    throw e;
  }
}

async function failOnNonSuccess(response: Response): Promise<Response> {
  if (response.ok) {
    return response;
  }
  const body = await response.text();
  if (isProblemJsonContentType(response.headers.get('Content-Type'))) {
    throw new ProblemJsonError(body, response);
  }

  // It seems TypeError sometimes is just a {message: "Failed to fetch", stack: "TypeError: Failed to fetch"} or similar
  try {
    const json = JSON.parse(body);
    if (
      ['message'].every((k) => Object.keys(json).includes(k)) &&
      /Failed to fetch|Load Failed/i.test(json.message)
    ) {
      log.info('[DEBUG] Got json body that included network error');
      return Promise.reject(new IgnoredNetworkError(new Error(json.message)));
    }
  } catch (e) {
    if (/Failed to fetch|Load Failed/i.test(body)) {
      log.info('[DEBUG] Got string body that included network error');
      return Promise.reject(new IgnoredNetworkError(new Error(body)));
    }
  }
  throw new ResponseError(body, response);
}

function innerFetcher(
  url: RequestInfo,
  options?: RequestInit,
  isRetry = false
): Promise<Response> {
  if (
    isInEmergencyMode(EmergencyMode.Aid) &&
    lastEmergencyModeReportTimestamp() >
      new Date().getTime() - EMERGENCY_MODE_RETRY_DELAY // Recheck when older than 60 seconds (for SPAs)
  ) {
    return Promise.reject(new EmergencyModeError());
  }

  try {
    return fetch(url, options)
      .catch(handleNetworkError)
      .then(handleEmergencyMode)
      .then(serverErrorRetryHandler(url, options, isRetry))
      .then(failOnNonSuccess);
  } catch (error) {
    log.info('[DEBUG] Reached sync error', error);
    return Promise.reject(new IgnoredNetworkError(error));
  }
}

export const fetcher = (
  url: RequestInfo,
  options?: RequestInit
): Promise<Record<string, unknown> | null> =>
  innerFetcher(url, options).then(unmarshalResponse);

export const schemaVerifiedFetch = <Schema extends rt.Runtype>(
  schema: Schema,
  url: RequestInfo,
  options?: RequestInit
): Promise<rt.Static<Schema>> =>
  innerFetcher(url, options)
    .then(unmarshalResponseWithRawBody)
    .then(({ rawBody, json }) => {
      try {
        return schema.check(json);
      } catch (e) {
        if (e instanceof ValidationError) {
          log.error(`Got validation error on body: ${rawBody}`, {
            fetch: { url, options },
            error: e,
          });
        }
        throw e;
      }
    });
