import getGlobalThis from '../../utils/getGlobalThis';
import store from '../../store';

enum CMPEvents {
  DIALOG = 'cmp-message-dialog',
}

declare global {
  interface WindowEventMap {
    [CMPEvents.DIALOG]: CustomEvent<{ displayed: boolean }>;
  }
}

export async function cmpWatcher(): Promise<void> {
  getGlobalThis().addEventListener(CMPEvents.DIALOG, (e) => {
    if (typeof e?.detail?.displayed === 'boolean') {
      store.merge('internalState', {
        isCMPDialogOpen: e.detail.displayed,
      });
    }
  });
}
