import * as rt from 'runtypes';

import cache from '../cache';
import log from '../utils/log';
import { getAidDomain } from '../utils/url';
import { schemaVerifiedFetch } from '../utils/fetcher';
import ResponseError from '../exceptions/ResponseError';
import IgnoredNetworkError from '../exceptions/IgnoredNetworkError';

export const CACHE_KEY = 'haveTriedThirdPartyLoginCheck';
export const CACHE_TTL = 60 * 60 * 1000; // One hour

const ServiceSessionSchema = rt.Record({
  user_name: rt.String,
  tracking_key: rt.String,
});

const PlutoErrorSchema = rt.Record({
  code: rt.String,
});

const PlutoErrorsSchema = rt.Record({
  errors: rt.Array(PlutoErrorSchema),
});

const IGNORED_CODES = [
  'session.not_found',
  'session.not_specified',
  'user.deactivated',
];
const WARNING_CODE = ['session.invalid_for_domain', 'user.not_found'];

const resolveBadPlutoResponse = async (response: ResponseError) => {
  let error: rt.Static<typeof PlutoErrorSchema>;
  try {
    [error] = PlutoErrorsSchema.check(JSON.parse(response.body)).errors;
  } catch (error) {
    log.warn('Unable to parse error body from pluto', { error });
    throw error;
  }
  if (IGNORED_CODES.includes(error?.code)) {
    return;
  }
  if (WARNING_CODE.includes(error?.code)) {
    log.warn(
      `Pluto responded with HTTP/${response.response.status}: (${error.code}) while fetching current session`,
      { error, response }
    );
    return;
  }
  log.error(
    `Pluto responded with HTTP/${response.response.status}: ${response.response.statusText}) when fetching current session`,
    { error, response }
  );
  throw new Error();
};

const getAidSession = (): Promise<rt.Static<
  typeof ServiceSessionSchema
> | null> =>
  schemaVerifiedFetch(
    ServiceSessionSchema,
    `https://${getAidDomain()}/api/pluto/v1/sessions/current`,
    {
      mode: 'cors',
      credentials: 'include',
    }
  ).catch(async (error) => {
    if (error instanceof IgnoredNetworkError) {
      return null;
    }
    if (error instanceof ResponseError) {
      try {
        await resolveBadPlutoResponse(error);
        return null;
      } catch {
        return null;
      }
    }
    log.warn('Unhandled error fetching data from session from aid.no', {
      error,
      extendedDebug: {
        type: typeof error,
        isError: error instanceof Error,
      },
    });
    return null;
  });

export default async function thirdPartyCookie() {
  // TODO: Should perhaps use cookie for this? Cache as it stands now only survives on the current page until vi replace it with something more persistent
  if (await cache.get(CACHE_KEY, rt.Boolean)) {
    return false;
  }
  await cache.set(CACHE_KEY, true, CACHE_TTL);
  return !!(await getAidSession());
}
