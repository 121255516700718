import { listenWithExclusivity } from '../utils/customEventWrapper';
import store from '../store';
import log from '../utils/log';

import { AmediaUserEvents } from './eventMap';

export const internalLocationHref = (url: string) => {
  window.location.href = url;
};

export const internalLocationReload = () => window.location.reload();
export const internalLocationReplace = (url: string) =>
  window.location.replace(url);

export const missingWindowContext = () => typeof window === 'undefined';

const handleNavigation = ({
  detail: { type, url, replace },
}: WindowEventMap[AmediaUserEvents.REQUEST_NAVIGATION]) => {
  switch (type) {
    case 'location':
      if (!url) {
        throw new Error('Url required for navigation');
      }
      if (replace) {
        internalLocationReplace(url);
      } else {
        internalLocationHref(url);
      }
      break;
    case 'reload':
      internalLocationReload();
      break;
    default:
      throw new Error(`Unknown navigation type (${type})`);
  }
};

export const listenForNavigationRequests = () =>
  listenWithExclusivity(AmediaUserEvents.REQUEST_NAVIGATION, (evt) => {
    if (missingWindowContext()) {
      log.error(
        'Navigation attempted context without window. Be careful not to use interactive UX code in workers and other settings.'
      );
      return;
    }

    try {
      handleNavigation(evt);
      /**
       * This will prevent any further event dispatching.
       * Effectively having requesters of data wait "forever" (the few
       * milliseconds) until we navigate away
       */
      store.merge('internalState', { isPoisoned: true });
    } catch (e) {
      log.error(e?.message);
    }
  });
