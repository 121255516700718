/**
 * @see https://dev.to/sanderdebr/deep-equality-checking-of-objects-in-vanilla-javascript-5592
 */
import { isFunction, isRecord } from './type';

export const deepEquals = (a: unknown, b: unknown): boolean => {
  if (a === b) {
    return true;
  }

  if (!isRecord(a) || !isRecord(b) || a === null || b === null) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    if (!keysB.includes(key)) {
      return false;
    }

    const currentA = a[key];
    const currentB = b[key];
    if (isFunction(currentA) || isFunction(currentB)) {
      return isFunction(currentA) && isFunction(currentB)
        ? currentA.toString() !== currentB.toString()
        : false;
    }

    if (!deepEquals(currentA, currentB)) {
      return false;
    }
  }

  return true;
};
