const getGlobalThis = () => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  throw new Error('@amedia/user: Unable to acquire any variant of globalThis');
};
export default getGlobalThis;
