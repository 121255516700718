import { saveNamespace } from '../clients/europa';

export class Namespace {
  private readonly name: string;
  private readonly clientId: string;
  public data: Record<string, string>;

  constructor(props: {
    name: string;
    clientId?: string;
    data: Record<string, string>;
  }) {
    this.name = props.name;
    this.clientId = props.clientId;
    this.data = props.data;
  }

  public save() {
    this.validate();
    return saveNamespace(this.clientId, this.name, this.data);
  }

  private validate() {
    const invalidKeys = Object.keys(this.data).filter((key) => key.length > 50);
    if (invalidKeys.length > 0) {
      throw new Error('One or more keys are too long (max 50 chars)');
    }
  }
}

/**
 * The Proxy instance is used to access data in namespace like before, but
 * also provide a scoped save function to ensure we write back to the same
 * namespace/client as we started with.
 */
export const ProxiedNamespace = (namespace: Namespace) => {
  let isDirty = false;

  return new Proxy(namespace, {
    get(target, key) {
      if (key === 'save') {
        return async () => {
          if (!isDirty) {
            return Promise.resolve();
          }
          await namespace.save();
          isDirty = false;
        };
      }
      return target.data[key.toString()];
    },
    set(target, key, value) {
      if (key.toString() === 'save') {
        throw new Error("Key 'save' is a reserved word");
      }
      if (
        value === undefined &&
        Object.getOwnPropertyNames(target.data).includes(key.toString())
      ) {
        isDirty = true;
        return delete target.data[key.toString()];
      }
      if (target.data[key.toString()] !== value) {
        isDirty = true;
        target.data[key.toString()] = value;
      }
      return true;
    },
    has(target, key) {
      return Object.keys(target.data).includes(key.toString());
    },
    ownKeys(target) {
      return Object.keys(target.data);
    },
    getOwnPropertyDescriptor() {
      return {
        enumerable: true,
        configurable: true,
      };
    },
    deleteProperty(target, key) {
      return delete target.data[key.toString()];
    },
  });
};
