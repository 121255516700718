import { Static } from 'runtypes';
import { RuntypeBase } from 'runtypes/lib/runtype';

import log from '../utils/log';

import globalCache from './engines/global';
import { Engine } from './types';

let cacheEngine: Engine;

export const setCacheEngine = (engine: Engine) => {
  cacheEngine = engine;
};

const getCacheEngine = (): Engine => cacheEngine || globalCache;

const set = (key: string, value: unknown, expires: number) =>
  getCacheEngine().set(key, value, Date.now() + expires);

const remove = (key: string): Promise<void> => getCacheEngine().remove(key);

const get = async <T extends RuntypeBase>(
  key: string,
  validator: T
): Promise<Static<T> | void> => {
  const cacheEntry = await getCacheEngine().get(key);
  if (!cacheEntry) {
    return Promise.resolve();
  }
  if (Date.now() > cacheEntry.expires) {
    remove(key);
    return Promise.resolve();
  }
  try {
    return validator.check(cacheEntry.data);
  } catch (error) {
    log.error(error, { cacheData: cacheEntry.data });
    remove(key);
    return Promise.resolve();
  }
};

const clear = () => getCacheEngine().clear();

export default {
  get,
  set,
  remove,
  clear,
};
