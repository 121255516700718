import { AmediaUserEvents } from '../eventHandlers/eventMap';

import { dispatch } from './customEventWrapper';

const requestNavigation = (type: string, url?: string, replace?: boolean) => {
  dispatch(
    new CustomEvent(AmediaUserEvents.REQUEST_NAVIGATION, {
      detail: { type, url, replace },
    })
  );
};

export const reload = () => requestNavigation('reload');
export const replace = (url: string) =>
  requestNavigation('location', url, true);
export const navigateTo = (url: string) =>
  requestNavigation('location', url, false);
