import * as rt from 'runtypes';

import store from '../store';
import cache from '../cache';

import { requestAttributes } from './userData';
import { requestStorage } from './storage';

export const CACHE_REFRESH_BLOCK_KEY = 'refreshBlock';
export const OVERRIDE_REFRESH_BLOCK = true;

export const refreshData = async (overrideRefreshBlock = false) => {
  if (!overrideRefreshBlock) {
    const inRefreshBlock = await cache.get(CACHE_REFRESH_BLOCK_KEY, rt.Boolean);
    if (inRefreshBlock) {
      return;
    }
  }
  await cache.clear();
  await cache.set(CACHE_REFRESH_BLOCK_KEY, true, 5000);

  const { requestedAttributes, requestedStorage } = store.get('internalState');
  store.set('state', {}); // Nuke current state on purpose to get rid of known login state

  await requestAttributes(Array.from(requestedAttributes));

  if (!store.get('state')?.isLoggedIn) {
    return;
  }

  if (Object.keys(requestedStorage).length > 0) {
    await requestStorage(requestedStorage);
  }
};
