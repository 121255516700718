import { Runtype, Static } from 'runtypes';

import cache from '../cache';

import queue from './queue';

export type DataFetcherWithCacheTTL<T extends Runtype> = Promise<{
  ttl: number; // Milliseconds!
  data: Static<T>;
}>;

const mergeCache = <T>(oldCache: T, newCache: T): T => {
  // Since we cannot know how to deal with duplicates when newCache is array
  // always return newCache
  if (!oldCache || Array.isArray(newCache)) {
    return newCache;
  }

  // TODO: Possible bug? :)
  if (!newCache) {
    return oldCache;
  }

  if (typeof oldCache === 'object' && typeof newCache === 'object') {
    return { ...oldCache, ...newCache };
  }

  // Cannot merge (eg type is string etc)
  return newCache;
};

export default function getOrUpdate<T extends Runtype>(
  key: string,
  schema: T,
  fetch: () => DataFetcherWithCacheTTL<T>,
  cacheQualifier?: (cacheData: Static<T>) => boolean
): Promise<Static<T>> {
  return new Promise((resolve, reject) => {
    queue.add(key, { resolve, reject });
    if (queue.peekQueueSize(key) > 1) {
      return;
    }

    cache.get(key, schema).then((cacheResponse) => {
      if (cacheResponse && (!cacheQualifier || cacheQualifier(cacheResponse))) {
        queue.getAll(key).forEach((promise) => {
          promise.resolve(cacheResponse);
        });
        return;
      }
      fetch()
        .then((res) => {
          const newCache = cacheResponse
            ? mergeCache(cacheResponse, res.data)
            : res.data;
          cache.set(key, newCache, res.ttl);
          queue.getAll(key).forEach((promise) => {
            promise.resolve(res.data);
          });
        })
        .catch((error) => {
          queue.getAll(key).forEach((promise) => {
            promise.reject(error);
          });
        });
    });
  });
}
