import * as rt from 'runtypes';

import cache from '../cache';
import log from '../utils/log';
import Debouncer from '../utils/debouncer';
import { fetcher, schemaVerifiedFetch } from '../utils/fetcher';
import IgnoredNetworkError from '../exceptions/IgnoredNetworkError';
import ResponseError from '../exceptions/ResponseError';
import ValidationError from '../exceptions/ValidationError';

const CACHE_DURATION = 5 * 60000; // 5 minutes
const DEBOUNCE_WAIT_TIME = 20; // 20ms wait before actually calling Europa

export const EuropaNamespaceSchema = rt.Dictionary(rt.String, rt.String);
export const EuropaSchema = rt.Dictionary(EuropaNamespaceSchema, rt.String);
export const EuropaClientSchema = rt.Dictionary(EuropaSchema, rt.String);
const EuropaErrorSchema = rt.Record({
  errors: rt.Array(
    rt.Record({
      code: rt.String,
      detail: rt.String,
    })
  ),
});

export const clearLocalCache = () => cache.remove('europa');
// When we don't fetch on behalf of a specific client.
export const DEFAULT_CLIENT_ID = 'default';
export type ClientId = string;

function mergeCache(
  cachedData: rt.Static<typeof EuropaSchema> | void,
  newData: rt.Static<typeof EuropaSchema>
) {
  return Object.entries(newData).reduce(
    (mergedNamespaces, [namespace, data]) => ({
      ...mergedNamespaces,
      [namespace]: data,
    }),
    cachedData || {}
  );
}

const updateNamespaceInCache = async (
  clientId: ClientId,
  namespace: string,
  data: rt.Static<typeof EuropaNamespaceSchema>
) => {
  const cachedData = await cache.get('europa', EuropaClientSchema);
  const updatedClient = mergeCache((cachedData && cachedData[clientId]) || {}, {
    [namespace]: data,
  });
  const updatedData = {
    ...cachedData,
    [clientId]: updatedClient,
  };
  return cache.set('europa', updatedData, Date.now() + CACHE_DURATION);
};

const debouncers: Record<
  string,
  Debouncer<
    Array<keyof rt.Static<typeof EuropaSchema>>,
    rt.Static<typeof EuropaSchema>
  >
> = {};
function getDebouncedClient(clientId: ClientId) {
  if (!debouncers[clientId]) {
    debouncers[clientId] = new Debouncer<
      Array<keyof rt.Static<typeof EuropaSchema>>,
      rt.Static<typeof EuropaSchema>
    >(
      DEBOUNCE_WAIT_TIME,
      [],
      Debouncer.stringListParamsAccumulator,
      (params) => {
        return schemaVerifiedFetch(
          rt.Record({ namespaces: EuropaSchema }),
          `/api/europa/v1/me/${params.join(',')}`,
          {
            ...(clientId !== DEFAULT_CLIENT_ID && {
              headers: {
                'X-Client-ID': clientId,
              },
            }),
          }
        )
          .then((d) => d.namespaces)
          .catch((error) => {
            if (error instanceof IgnoredNetworkError) {
              return {};
            }
            if (error instanceof rt.ValidationError) {
              log.error(
                `Europa - EuropaSchema, Runtype check failed. ${
                  error.name
                }: ${JSON.stringify(error.details)}`,
                error
              );
            }
            throw error;
          });
      }
    );
  }
  return debouncers[clientId];
}

export const getNamespaces = async <
  S extends rt.Static<typeof EuropaSchema>,
  Namespaces extends Array<keyof S>,
>(
  clientId: ClientId,
  namespaces: Namespaces,
  options: { ignoreCache: boolean } = { ignoreCache: false }
): Promise<Pick<S, Namespaces[number]>> => {
  if (namespaces.length === 0) {
    throw new Error('namespaces array cannot be of length 0');
  }

  let missingNamespaces = namespaces as string[];
  const cachedData = ((await cache.get('europa', EuropaClientSchema)) || {})[
    clientId
  ];
  if (!options.ignoreCache) {
    missingNamespaces = (
      cachedData
        ? namespaces.filter((n: string) => !Object.keys(cachedData).includes(n))
        : namespaces
    ) as string[];
    if (cachedData && missingNamespaces.length === 0) {
      // Yay! Everything we need is in the cache!
      return cachedData as Pick<S, Namespaces[number]>;
    }
  }

  const fetchedNamespaces =
    await getDebouncedClient(clientId).perform(missingNamespaces);
  await Promise.all(
    Object.entries(fetchedNamespaces).map(([namespace, data]) =>
      updateNamespaceInCache(clientId, namespace, data)
    )
  );

  return Object.entries(mergeCache(cachedData, fetchedNamespaces))
    .filter(([namespace]) => namespaces.includes(namespace))
    .reduce(
      (returnedEntries, [namespace, data]) => ({
        ...returnedEntries,
        [namespace]: data,
      }),
      {} as Pick<S, Namespaces[number]>
    );
};

export const saveNamespace = (
  clientId: ClientId,
  namespace: keyof rt.Static<typeof EuropaSchema>,
  data: rt.Static<typeof EuropaNamespaceSchema>
) =>
  fetcher(`/api/europa/v1/me/${namespace}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(clientId !== DEFAULT_CLIENT_ID && {
        'X-Client-ID': clientId,
      }),
    },
  })
    .then(() => getNamespaces(clientId, [namespace], { ignoreCache: true }))
    .catch((e) => handleEuropaError(e, namespace, data));

export const updateNamespace = (
  clientId: ClientId,
  namespace: keyof rt.Static<typeof EuropaSchema>,
  data: rt.Static<typeof EuropaNamespaceSchema>
) =>
  schemaVerifiedFetch(
    rt.Record({ namespaces: EuropaSchema }),
    `/api/europa/v1/me/${namespace}`,
    {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...(clientId !== DEFAULT_CLIENT_ID && {
          'X-Client-ID': clientId,
        }),
      },
    }
  )
    .then((r) => {
      updateNamespaceInCache(clientId, namespace, data);
      return r;
    })
    .catch((e) => handleEuropaError(e, namespace, data));

function handleEuropaError(error: Error, namespace: string, data: unknown) {
  if (error instanceof IgnoredNetworkError) {
    return {};
  }
  if (error instanceof ResponseError) {
    const data = EuropaErrorSchema.check(JSON.parse(error.body));
    if (data.errors.length > 0) {
      throw new ValidationError(data.errors[0].code, data.errors[0].detail);
    }
  }
  log.error(`Europa - Could not save namespace. namespace: ${namespace}`, {
    data,
    error,
  });
  throw error;
}
