import { getCookie, removeCookie } from '../../lib/cookie';
import UserDataRequest from '../../lib/UserDataRequest';
import {
  setNonUserAccessCookie,
  setUserAccessCookie,
} from '../../actions/authorization';
import { getCurrentSiteDomain } from '../../utils/url';

const validCookieGenerator = 'jupiter';

async function verifyAndSetUserAccessCookie() {
  const cookieName = 'VSTOKEN';
  const siteDomain = getCurrentSiteDomain();
  const cookie = getCookie(cookieName);

  new UserDataRequest()
    .withAttributes(['uuid'])
    .fetch()
    .then(async ({ attributes, state }) => {
      if (state.isLoggedIn === false) {
        if (typeof cookie === 'string') {
          // We don't want logged out users to have lingering VSTOKEN cookies
          removeCookie(cookieName);
        }
        return;
      }

      if (typeof cookie !== 'string') {
        // We want to set a VSTOKEN cookie for the user so it's ready upon next page load
        await setUserAccessCookie(siteDomain);
        return;
      }

      /* The format of the cookie is:
       * <0 - signature>:<1 - timestamp when cookie stops being valid>:<2 - access_features>:<3 - user_id>:<4 - second level domain>:<5 - generator name (jupiter)> */
      const cookieParts = cookie.split(':');

      const validTo = new Date(parseInt(cookieParts[1], 10) * 1000);
      validTo.setDate(
        validTo.getDate() - 1
      ); /* Set the time one day back so we can update it if it's only valid for one more day */

      // Make sure that current cookie is not too old, is created for logged in user for this domain by jupiter
      if (
        validTo.getTime() > Date.now() &&
        cookieParts[3] === attributes.uuid &&
        siteDomain.includes(cookieParts[4]) &&
        cookieParts[5] === validCookieGenerator
      ) {
        return; // Cookie is valid for more than 24 hours, keep it as it is
      }
      await setUserAccessCookie(siteDomain);
    });
}

async function verifyAndSetNonUserAccessCookie() {
  const cookieName = 'VSTOKEN2';
  const siteDomain = getCurrentSiteDomain();
  const cookie = getCookie(cookieName);

  const {
    state: { isLoggedIn },
  } = await new UserDataRequest().fetch();
  if (isLoggedIn) {
    if (typeof cookie !== 'string') {
      await setNonUserAccessCookie(siteDomain);
      return;
    }
    /* The format of the cookie is:
     * <0 - signature>:<1 - timestamp when cookie stops being valid>:<2 - access_features>:<3 - uuid>:<4 - second level domain>:<5 - generator name (jupiter)>
     */
    const cookieParts = cookie.split(':');
    const validTo = new Date(parseInt(cookieParts[1], 10) * 1000);
    // // Check that token is valid for the next 30 minutes otherwise we need to update it
    validTo.setMinutes(validTo.getMinutes() - 30);

    // Make sure that current cookie is not too old and is created for this domain by jupiter
    if (
      validTo.getTime() > Date.now() &&
      siteDomain.includes(cookieParts[4]) &&
      cookieParts[5] === validCookieGenerator
    ) {
      return;
    }
    await setNonUserAccessCookie(siteDomain);
  }
}

export default async function accessCookieRefresher() {
  await Promise.all([
    verifyAndSetUserAccessCookie(),
    verifyAndSetNonUserAccessCookie(),
  ]);
}
