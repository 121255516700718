import {
  hasAccess,
  hasRecentlyPerformedPaywallReload,
  setAccessCookies,
  setHasPerformedPaywallReload,
} from '../actions/authorization';
import { getCurrentSiteDomain } from '../utils/url';
import PaywallError from '../exceptions/PaywallError';
import { broadcastAccess } from '../clients/jupiter';
import { isRunningOnLocalhost } from '../utils/localhostCheck';
import { reload, replace } from '../utils/location';
import { getPrimarySite } from '../clients/shamo';
import { AccessFeatures } from '../types';
import store from '../store';

import {
  SiteAccess,
  SiteAccessResponse,
  verifyIsLoggedIn,
} from './SiteAccessRequest';

const setAccessCookiesAndGetAccessFeatures = async (
  requestedAccessFeatures: AccessFeatures
): Promise<SiteAccess> => {
  const response = await setAccessCookies(getCurrentSiteDomain());
  const accessDenied = !hasAccess(
    response.accessFeatures,
    requestedAccessFeatures
  );
  if (!response.success && accessDenied) {
    throw new PaywallError(
      'Could not set access cookies',
      PaywallError.ERROR_ENABLING_ACCESS_FAILED
    );
  }
  return {
    denied: accessDenied,
    granted: !accessDenied,
    accessFeatures: response.accessFeatures,
  };
};

export type PaywallUnlockResponse = SiteAccessResponse;

class PaywallUnlockRequest {
  private reload = false;

  private requestedUrl: string | undefined;

  private siteDomain: string = getCurrentSiteDomain();

  constructor(private requestedAccessFeatures: string[] = []) {
    if (!Array.isArray(this.requestedAccessFeatures)) {
      throw new TypeError('accessFeatures must be an array');
    }
  }

  public withSiteDomain(domain: string): this {
    if (typeof domain !== 'string') {
      throw new TypeError('domain must be of type string');
    }
    this.siteDomain = domain;
    return this;
  }

  /**
   * @deprecated Required now. Set in constructor
   */
  public withAccessFeatures(accessFeatures: string[]): this {
    if (!Array.isArray(accessFeatures)) {
      throw new TypeError('accessFeatures must be an array');
    }
    this.requestedAccessFeatures = accessFeatures;
    return this;
  }

  public reloadOnAccess(): this {
    this.reload = true;
    return this;
  }

  public redirectOnAccess(requestedUrl: string): this {
    if (typeof requestedUrl !== 'string') {
      throw new TypeError('requestedUrl must be of type string');
    }
    this.requestedUrl = requestedUrl;
    return this;
  }

  public async tryUnlock(): Promise<PaywallUnlockResponse> {
    if (isRunningOnLocalhost()) {
      return Promise.reject(
        new Error('@amedia/user cannot unlock paywall on localhost')
      );
    }

    store.merge('internalState', {
      paywallUnlockRequested: true,
    });

    if (!(await verifyIsLoggedIn())) {
      return { isLoggedIn: false, hasAccess: false, accessFeatures: [] };
    }

    const currentAccess: SiteAccess =
      await setAccessCookiesAndGetAccessFeatures(this.requestedAccessFeatures);

    if (currentAccess.denied) {
      const primarySite = await getPrimarySite(this.requestedAccessFeatures);
      return {
        isLoggedIn: true,
        hasAccess: false,
        primarySite,
        accessFeatures: currentAccess.accessFeatures,
      };
    }

    await broadcastAccess();

    if (this.reload || this.requestedUrl) {
      // Ensures we don't get stuck in a reload/redirect loop
      if (hasRecentlyPerformedPaywallReload(this.requestedUrl)) {
        throw new PaywallError(
          'Unlock check already performed.',
          PaywallError.ERROR_RELOADED_NO_ACCESS
        );
      }

      setHasPerformedPaywallReload(this.requestedUrl);
      if (this.reload) {
        reload();
      }

      if (this.requestedUrl) {
        replace(this.requestedUrl);
      }

      return new Promise(() => {
        /* Don't resolve tryUnlock promise if we're being reloaded/navigated away */
      });
    }
    return {
      isLoggedIn: true,
      hasAccess: true,
      accessFeatures: currentAccess.accessFeatures,
    };
  }
}

export default PaywallUnlockRequest;
