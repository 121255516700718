import store from '../store';

export enum EmergencyMode {
  Aid = 'aid',
  Paywall = 'paywall',
}

export function getActiveEmergencyModes(): string[] {
  return store.get('state')?.emergencyMode || [];
}

/**
 * Check if in emergency mode. If param is specified, it checks for specific mode
 * @param emergencyMode
 */
export function isInEmergencyMode(emergencyMode?: EmergencyMode) {
  const modes = getActiveEmergencyModes();
  return emergencyMode ? modes.includes(emergencyMode) : modes.length > 0;
}

export function lastEmergencyModeReportTimestamp() {
  return store.get('internalState')?.lastEmergencyModeReportTimestamp || 0;
}
